<template>
  <v-main>
    <v-container
      fluid
      fill-height
      class="login"
    >
      <v-row
        class="align-center justify-center mb-4"
      >
        <v-col
          cols="12"
          sm="8"
          md="6"
          lg="4"
        >
          <img
            src="/images/politmonitoring-logo-big.png"
            class="logo mb-3 d-block mx-auto"
          >
          <v-form
            ref="loginForm"
            v-model="valid"
            @submit.prevent="onSubmit"
          >
            <v-card class="elevation-12">
              <v-toolbar color="white">
                <v-toolbar-title>{{ $t('Login.login') }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  v-model="username"
                  :rules="loginRules"
                  :label="$t('Login.email')"
                  autofocus
                  prepend-icon="$vuetify.icons.user"
                  name="login"
                  type="email"
                />
                <v-text-field
                  id="password"
                  v-model="password"
                  :rules="passwordRules"
                  :label="$t('Login.password')"
                  prepend-icon="$vuetify.icons.key"
                  name="password"
                  type="password"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  :loading="loading"
                  :disabled="!valid || loading"
                  tile
                  type="submit"
                  color="primary"
                >
                  {{ $t('Login.login') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
      <v-row class="align-center justify-center mb-2">
        <v-col
          md="6"
          lg="8"
          cols="12"
        >
          <p>
            Mit dem Onlinetool zur Politbeobachtung können Sie die für Sie relevanten Geschäfte über den ganzen politischen Prozess verfolgen.
            So sind Sie rechtzeitig über neue Entwicklungen im Parlament und Bundesrat informiert. Sie haben die Wahl aus insgesamt 21 Themengebieten, die Sie einzeln oder kombiniert abonnieren können.<br />>&nbsp;<a href="https://politimpuls.ch/home/politinformation/#politmonitoring" target="_blank">weitere Infos</a><br>
          </p>
          <p>
            Weitere Services von politimpuls:
          </p>
          <ul>
            <li>Wie bringen Sie Ihre Interessen am wirkungsvollsten in die Politik ein? Wir unterstützen Sie von der Analyse über die Erarbeitung der Strategie bis zur Umsetzung – bei politischen Vorstössen, Gesetzesvorlagen, Referenden und Volksinitiativen.<br />>&nbsp;<a href="https://politimpuls.ch/home/politcampaigning/" target="_blank">politcampaigning</a></li>
            <li>Die Strategie beschreibt den besten Weg zu Ihrem Ziel. Durch die Entwicklung einer Strategie konfrontieren Sie sich mit der aktuellen Situation und zukünftigen Entwicklungen. Dadurch gewinnt Ihre Organisation an Konsistenz und Gewissheit.<br />>&nbsp;<a href="https://politimpuls.ch/home/politstrategie/" target="_blank">politstrategie</a></li>
            <li>Sie wollen lernen, wie Sie Ihre Anliegen wirkungsvoll in die Politik einbringen? Im Rahmen unseres Weiterbildungsangebotes zeigen wir Ihnen die Funktionsweise des politischen Systems auf und wie Sie sich darin Gehör verschaffen. Unsere Workshops können einzeln oder als Lehrgang gebucht werden.<br />>&nbsp;<a href="https://politimpuls.ch/home/polittraining/" target="_blank">polittraining</a></li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { defaultRouteName } from '@/router'

export default {
  metaInfo () {
    return {
      title: this.$t('Login.login'),
    }
  },
  data () {
    return {
      valid: true,
      username: '',
      password: '',
      passwordRules: [
        v => !!v || this.$t('Login.passwordRequired'),
      ],
      loginRules: [
        v => !!v || this.$t('Login.emailRequired'),
      ],
    }
  },
  computed: {
    loading () {
      return this.$store.state.auth.loading
    },
  },
  methods: {
    ...mapActions('auth', ['login']),
    ...mapMutations('app', ['setSnack']),
    async onSubmit () {
      await this.login({
        login: this.username,
        password: this.password,
      })
      if (this.$store.state.auth.user) {
        this.$router.push({ name: defaultRouteName })
      } else {
        this.password = ''
        this.$refs.loginForm.resetValidation()
      }
    },
  },
}
</script>
